import React, { useState } from "react";
import { Box, Typography, IconButton, InputBase, Divider } from "@mui/material";
import { useDrag } from "react-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HasuraService from "../../../hasura/hasura-service";
import { EpicModal } from "./epic-Box-Modal";
import { StoryModal } from "./story-Box-Modal";

export const BlankItemBox = ({ itemData, type, epicId }) => {
  const [, drag] = useDrag({
    type: type,
    item: { type: type, itemData, epicId },
  });
  return (
    <div ref={drag}>
      <Box
        height={150}
        width={175}
        border={`1px solid #FFFFFF`}
        borderLeft={`5px solid #FFFFFF`}
        borderRadius={1}
        padding={1}
        marginBottom={1.5}
        margin={1.5}
      ></Box>
    </div>
  );
};

export const ItemBox = ({ itemData, type, epicId, setStories }) => {
  const [confirmTaskDelete, setConfirmTaskDelete] = useState(false);
  const [taskModalVisable, setTaskModalVisable] = useState(false);
  const [, drag] = useDrag({
    type: type,
    item: { type: type, itemData, epicId },
  });

  const handleDelete_Story = async () => {
    const hs = new HasuraService();

    try {
      hs.deleteStory(itemData.id);
    } catch (error) {
      console.error(error);
    }

    setStories((prevData) => prevData.filter((c) => c.id !== itemData.id));
  };

  return (
    <div ref={drag}>
      <StoryModal
        visable={taskModalVisable}
        setTaskModalVisable={setTaskModalVisable}
        itemData={{ type: "task", data: itemData, epicId: epicId }}
        setStories={setStories}
      />
      <Box
        height={150}
        width={175}
        border={`1px solid ${itemData.type === "feature" ? "lightgreen" : "red"}`}
        borderLeft={`5px solid ${itemData.type === "feature" ? "lightgreen" : "red"}`}
        borderRadius={1}
        textAlign="left"
        padding={1}
        marginBottom={1.5}
        margin={1.5}
        display="flex"
        flexDirection="column"
        position="relative"
        overflow="hidden"
        boxShadow={1}
      >
        <Box flex={4} overflow="hidden" onClick={() => setTaskModalVisable(true)}>
          <Typography gutterBottom color="grey" fontSize="15px">
            {itemData.id}
          </Typography>
          <Typography>{itemData.title}</Typography>
        </Box>
        {confirmTaskDelete ? (
          <Box display="flex">
            <Box flex={1} onClick={() => setTaskModalVisable(true)} />
            <IconButton aria-label="delete-confirm" onClick={handleDelete_Story}>
              <CheckIcon />
            </IconButton>
            <IconButton aria-label="close" onClick={() => setConfirmTaskDelete(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box display="flex">
            <Box flex={1} onClick={() => setTaskModalVisable(true)} />
            <IconButton aria-label="delete" onClick={() => setConfirmTaskDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </div>
  );
};

export const EpicInfoBox = ({ epicData, epicId, epics, setStories, user, stories, setEpics }) => {
  const [input, setInput] = useState("");
  const [taskModalVisable, setTaskModalVisable] = useState(false);

  const handleClick_newStory = async () => {
    const hs = new HasuraService();
    const fixedInput = input.replace(/"/g, "'");

    const storyOrderNumber =
      stories.reduce((a, c) => {
        return c.orderNumber > a ? c.orderNumber : a;
      }, 0) + 1;

    let storyid = user
      ? await hs.createStory(storyOrderNumber, fixedInput, "feature", user.id, epicId)
      : Math.floor(Math.random() * 10000);

    const newStory = {
      collapsed: false,
      description: "",
      id: storyid,
      orderNumber: storyOrderNumber,
      title: input,
      type: "feature",
      column: "todos",
      epicid: epicId,
    };
    setStories((prevData) => [...prevData, newStory]);
    setInput("");
  };

  const boxColor = "gold";
  return (
    <Box
      height={150}
      width={175}
      border={`1px solid ${boxColor}`}
      borderLeft={`5px solid ${boxColor}`}
      borderRadius={1}
      textAlign="left"
      padding={1}
      marginBottom={1.5}
      margin={1.5}
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden"
      boxShadow={1}
    >
      <EpicModal
        visable={taskModalVisable}
        setTaskModalVisable={setTaskModalVisable}
        itemData={{ type: "story", data: epicData, epicId: epicId }}
        setEpics={setEpics}
      />
      <Box
        flex={4}
        overflow="hidden"
        onClick={() => setTaskModalVisable(true)}
        sx={{ cursor: "pointer" }}
      >
        <Typography gutterBottom color="grey" fontSize="15px">
          {epicId}
        </Typography>
        <Typography>{epicData.text}</Typography>
      </Box>
      <Box border="1px solid lightgrey" borderRadius={1} display="flex" alignItems="center">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Add Task"
          inputProps={{ "aria-label": "add task" }}
          variant="standard"
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="add"
          onClick={handleClick_newStory}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
