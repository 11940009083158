import * as Auth from "aws-amplify/auth";
import { sparseError } from "./sparseError";

function changePassword(oldP, newP) {
  return Auth.fetchUserAttributes()
    .then((user) => {
      return Auth.resetPassword(user, oldP, newP);
    })
    .then((data) => data)
    .catch((err) => {
      console.error(err);
      return sparseError(err);
    });
}

export { changePassword };
