import * as Auth from "aws-amplify/auth";

export async function resendConfirmationCode(username) {
  try {
    await Auth.resendSignUpCode(username);
    console.error("code resent successfully");
  } catch (err) {
    console.error("error resending code: ", err);
  }
}
