import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputBase,
  Divider,
  IconButton,
  CircularProgress,
  Switch,
} from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Header } from "./components/header";
import { RearrangeStoriesModal } from "./components/modals/rearrangeStoriesModal";
import { RearrangeEpicsModal } from "./components/modals/rearrangeEpicsModal";
import { Footer } from "./components/footer";

import HasuraService from "../hasura/hasura-service";
import { getCurrentUser } from "../auth/auth-methods";
import { firstTasks, firstStories } from "./utils";
import { ScrumBoard } from "./components/scrumBoard/scrumBoard";
import { EpicBoard } from "./components/epicBoard/epicBoard";

import AddIcon from "@mui/icons-material/Add";

const CustomTextField = ({ handleClick, iconButton, placeholder }) => {
  const [input, setInput] = useState("");

  return (
    <Box border="1px solid lightgrey" borderRadius={1} display="flex" alignItems="center">
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ "aria-label": "add task" }}
        variant="standard"
        onChange={(e) => setInput(e.target.value)}
        value={input}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="add"
        onClick={() => {
          handleClick(input, setInput);
          setInput("");
        }}
      >
        {iconButton}
      </IconButton>
    </Box>
  );
};

const SuperSimpleScrumMain = () => {
  const [user, setUser] = useState();
  const [storyType, setStoryType] = useState("feature");
  const [stories, setStories] = useState(firstStories);
  const [epics, setEpics] = useState([]);
  const [tasks, setTasks] = useState(firstTasks);
  const [rearrangeVisable, setRearrangeVisable] = useState(false);
  const [loadingValue, setLoadingValue] = useState(0);
  const [epicOrScrum, setEpicOrScrum] = useState("scrum");

  console.log("TASKS", tasks);

  useEffect(() => {
    if (!user) {
      getCurrentUser().then(async (currentUser) => {
        if (currentUser) {
          const hs = new HasuraService();
          const { Epics, Stories, Tasks, id } = await hs.getStuff(currentUser.email);
          const user = { email: currentUser.email, id: id };
          setUser(user);
          setEpics(Epics);
          setStories(Stories);
          setTasks(Tasks);
        }
      });
    }
  }, []);

  // takes the input and creates a new task
  // placing it in the todo column in the propper story
  // adds it to hasura
  const handleClick_newStory = async (input, setInput) => {
    input = input.replace(/"/g, "'");
    const storyOrderNumber =
      stories.reduce((a, c) => {
        return c.orderNumber > a ? c.orderNumber : a;
      }, 0) + 1;

    const hs = new HasuraService();
    const storyid = user
      ? await hs.createStory(storyOrderNumber, input, storyType, user.id, null)
      : stories.reduce((a, c) => (c.id > a ? c.id : a), 0) + 1;

    setStories((prevData) => {
      const newStory = {
        collapsed: false,
        description: "",
        id: storyid,
        orderNumber: storyOrderNumber,
        title: input,
        type: storyType,
        epicid: null,
      };
      return [...prevData, newStory];
    });
  };

  const handleClick_newEpic = async (input, setInput) => {
    input = input.replace(/"/g, "'");
    const epicOrderNumber =
      epics.reduce((a, c) => {
        return c.orderNumber > a ? c.orderNumber : a;
      }, 0) + 1;

    const hs = new HasuraService();
    const epicid = user
      ? await hs.createEpic(user.id, input, epicOrderNumber)
      : epics.reduce((a, c) => (c.id > a ? c.id : a), 0) + 1;

    setEpics((prevData) => {
      const newEpic = {
        collapsed: false,
        description: "",
        id: epicid,
        orderNumber: epicOrderNumber,
        title: input,
      };
      return [...prevData, newEpic];
    });
  };

  const handleScrumEpicSwitch = () => {
    if (user) {
      setEpicOrScrum((prevState) => (prevState === "scrum" ? "epic" : "scrum"));
    }
  };

  const handleFeatureBugSwitch = () => {
    setStoryType((prevState) => (prevState === "feature" ? "bug" : "feature"));
  };

  let preppedStories = stories
    .map((c) => {
      let tempObj = { ...c };
      const todos = tasks.filter((y) => y.storyid === tempObj.id && y.column === "todos");
      const doings = tasks.filter((y) => y.storyid === tempObj.id && y.column === "doings");
      const dones = tasks.filter((y) => y.storyid === tempObj.id && y.column === "dones");
      const tempTasks = [{ column: "tasks", description: c.description, text: c.title }];
      tempObj.todos = todos;
      tempObj.doings = doings;
      tempObj.dones = dones;
      tempObj.tasks = tempTasks;
      return tempObj;
    })
    .sort((a, b) => a.orderNumber - b.orderNumber);

  const preppedEpics = epics
    .map((c) => {
      let tempObj = { ...c };
      const todos = preppedStories.filter((y) => y.epicid === tempObj.id && y.column === "todos");
      const doings = preppedStories.filter((y) => y.epicid === tempObj.id && y.column === "doings");
      const dones = preppedStories.filter((y) => y.epicid === tempObj.id && y.column === "dones");
      const tempTasks = [{ column: "tasks", description: c.description, text: c.title }];
      tempObj.todos = todos;
      tempObj.doings = doings;
      tempObj.dones = dones;
      tempObj.tasks = tempTasks;
      return tempObj;
    })
    .sort((a, b) => a.orderNumber - b.orderNumber);

  preppedStories = preppedStories.filter(
    (story) =>
      !story.epicid || (story.epicid && (story.column === "doings" || story.column === "dones"))
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Box margin={-1} minHeight="100vh" display="flex" flexDirection="column" flex={1}>
        {epicOrScrum === "scrum" ? (
          <RearrangeStoriesModal
            rearrangeVisable={rearrangeVisable}
            setRearrangeVisable={setRearrangeVisable}
            stories={preppedStories}
            setStories={setStories}
          />
        ) : (
          <RearrangeEpicsModal
            rearrangeVisable={rearrangeVisable}
            setRearrangeVisable={setRearrangeVisable}
            epics={preppedEpics}
            setEpics={setEpics}
          />
        )}
        <Header user={user} setUser={setUser} setData={setStories} firstTasks={firstTasks} />
        <Box display="flex" flexDirection="column" flex={1}>
          {loadingValue ? (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
              <CircularProgress size={100} variant="determinate" value={loadingValue} />
            </Box>
          ) : (
            <Box overflow="auto" paddingTop={3}>
              <Box
                marginX={5}
                marginBottom={2}
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="space-between" // Add this line
              >
                <Box display="flex" alignItems="center">
                  <CustomTextField
                    handleClick={
                      epicOrScrum === "scrum" ? handleClick_newStory : handleClick_newEpic
                    }
                    iconButton={<AddIcon />}
                    placeholder={epicOrScrum === "scrum" ? "Add Story" : "Add Epic"}
                  />
                  {epicOrScrum === "scrum" && (
                    <Box display="flex" alignItems="center">
                      <Switch checked={storyType === "feature"} onChange={handleFeatureBugSwitch} />
                      <Typography>{storyType === "feature" ? "FEATURE" : "BUG"}</Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography>{epicOrScrum === "epic" ? "EPIC" : "SCRUM"}</Typography>
                  <Switch checked={epicOrScrum === "epic"} onChange={handleScrumEpicSwitch} />
                </Box>
              </Box>
              {epicOrScrum === "scrum" ? (
                <ScrumBoard
                  stories={preppedStories}
                  setStories={setStories}
                  tasks={tasks}
                  setTasks={setTasks}
                  setRearrangeVisable={setRearrangeVisable}
                  user={user}
                  epics={epics}
                />
              ) : (
                <EpicBoard
                  epics={preppedEpics}
                  setEpics={setEpics}
                  stories={stories}
                  setStories={setStories}
                  setRearrangeVisable={setRearrangeVisable}
                  user={user}
                />
              )}
            </Box>
          )}
          <Box flex={1} display="flex" justifyContent="center">
            <Footer />
          </Box>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default SuperSimpleScrumMain;
