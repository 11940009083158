import React, { useState } from "react";
import { Box, IconButton, Divider, Typography } from "@mui/material";
import { ItemBox, StoryInfoBox, BlankItemBox } from "./task";
import { generateRandomKey, padData } from "../../utils";
import { useDrop } from "react-dnd";
import HasuraService from "../../../hasura/hasura-service";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCurrentUser } from "../../../auth/auth-methods";

const StoryColumn = ({ columnData, type, storyId, setTaskModalVisable, setTasks }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ["todos", "doings", "dones"],
    hover: (item) => {},
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: async (droppedItem) => {
      if (droppedItem.storyId === storyId && droppedItem.type !== type) {
        let taskToUpdate = droppedItem.itemData;
        taskToUpdate.column = type;
        setTasks((prevData) => prevData.map((c) => (c.id === taskToUpdate.id ? taskToUpdate : c)));

        const hs = new HasuraService();
        try {
          hs.updateTaskColumn(taskToUpdate.id, type);
        } catch (error) {
          console.error(error);
        }
      }
    },
  });

  return (
    <div ref={drop}>
      <Box>
        {columnData.map((item) =>
          item === "" ? (
            <BlankItemBox itemData={item} type={type} storyId={storyId} key={generateRandomKey()} />
          ) : (
            <ItemBox
              itemData={item}
              type={type}
              storyId={storyId}
              key={generateRandomKey()}
              setTaskModalVisable={setTaskModalVisable}
              setTasks={setTasks}
            />
          )
        )}
      </Box>
    </div>
  );
};

const Story = ({ story, tasks, setStories, setTasks, setRearrangeVisable, user, epic }) => {
  const [confirmStoryDelete, setConfirmStoryDelete] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(story.collapsed);

  story = padData(story);

  const handleDelete_Story = async () => {
    const storyTasks = [...story.todos, ...story.doings, ...story.dones];
    const storyTaskids = storyTasks.map((c) => c.id).filter((c) => c);
    const tempTasks = [];

    for (let i = 0; i < tasks.length; i++) {
      if (storyTaskids.indexOf(tasks[i].id) === -1) {
        tempTasks.push(tasks[i]);
      }
    }
    const hs = new HasuraService();
    try {
      await hs.deleteTasks(storyTaskids);
      await hs.deleteStory(story.id);
    } catch (error) {
      console.error(error);
    }

    setStories((prevData) => prevData.filter((c) => c.id !== story.id));
    setTasks(tempTasks);
    setConfirmStoryDelete(false);
  };

  const collapseStory = async () => {
    const user = await getCurrentUser();
    setIsCollapsed(!isCollapsed);
    setStories((prevData) =>
      prevData.map((c) => (c.id === story.id ? { ...c, collapsed: !isCollapsed } : c))
    );
    if (user) {
      const hs = new HasuraService();
      try {
        await hs.updateStoryCollapsed(story.id, !isCollapsed);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const boxColor = story.type === "bug" ? "red" : "lightgreen";

  return !isCollapsed ? (
    <Box display="flex" marginBottom={2}>
      <Box marginTop={1}>
        <IconButton aria-label="collapse" onClick={collapseStory}>
          <ExpandLessIcon />
        </IconButton>
        {confirmStoryDelete ? (
          <Box display="flex" flexDirection="column">
            <IconButton aria-label="close" onClick={() => setConfirmStoryDelete(false)}>
              <CloseIcon />
            </IconButton>
            <IconButton aria-label="delete-confirm" onClick={handleDelete_Story}>
              <CheckIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <IconButton aria-label="delete" onClick={() => setConfirmStoryDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <IconButton aria-label="delete" onClick={() => setRearrangeVisable(true)}>
          <DragIndicatorIcon />
        </IconButton>
      </Box>
      <Box display="flex" border="1px solid lightgrey" borderRadius={1} padding={1}>
        <StoryInfoBox
          setTasks={setTasks}
          setStories={setStories}
          user={user}
          story={story}
          epic={epic}
        />
        <Divider orientation="vertical" flexItem sx={{ marginY: 2, marginX: 2 }} />
        <StoryColumn
          columnData={story.todos}
          type={"todos"}
          storyId={story.id}
          setTasks={setTasks}
        />
        <Divider orientation="vertical" flexItem sx={{ marginY: 2, marginX: 2 }} />
        <StoryColumn
          columnData={story.doings}
          type={"doings"}
          storyId={story.id}
          setTasks={setTasks}
        />
        <Divider orientation="vertical" flexItem sx={{ marginY: 2, marginX: 2 }} />
        <StoryColumn
          columnData={story.dones}
          type={"dones"}
          storyId={story.id}
          setTasks={setTasks}
        />
      </Box>
    </Box>
  ) : (
    <Box display="flex" marginBottom={2}>
      <IconButton aria-label="expand" onClick={collapseStory}>
        <ExpandMoreIcon />
      </IconButton>
      <Box
        display="flex"
        width={1000}
        border={`2px solid ${boxColor}`}
        borderRadius={1}
        padding={1}
        alignItems="center"
      >
        <Typography color="grey" fontSize="15px" marginRight={1}>
          {story.id}
        </Typography>
        <Typography>{story.tasks[0].text}</Typography>
      </Box>
    </Box>
  );
};

export const ScrumBoard = ({
  stories,
  setStories,
  tasks,
  setTasks,
  setRearrangeVisable,
  user,
  epics,
}) => {
  return (
    <Box width={1038}>
      <Box display="flex" flex={1} marginLeft={5} marginBottom={1}>
        <Box flex={1}></Box>
        <Box flex={1} textAlign="center">
          <Typography variant="h6">Todos</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="h6">Doing</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="h6">Done</Typography>
        </Box>
      </Box>
      {stories
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((story) => {
          let epic = {};
          if (story.epicid) {
            let e = epics.filter((c) => c.id === story.epicid);
            epic = e[0];
          }
          return (
            <Box key={story.id}>
              <Story
                story={story}
                tasks={tasks}
                setTasks={setTasks}
                stories={stories}
                setStories={setStories}
                setRearrangeVisable={setRearrangeVisable}
                user={user}
                epic={epic}
              />
            </Box>
          );
        })}
    </Box>
  );
};
