import * as Auth from "aws-amplify/auth";

export async function signOut(returnPage = null) {
  try {
    await Auth.signOut().then((x) => {
      if (returnPage) {
        window.location.href = returnPage;
      }
    });
  } catch (error) {
    console.error("error signing out: ", error);
  }
}
