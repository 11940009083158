import React, { useState } from "react";
import { Box, IconButton, Divider, Typography } from "@mui/material";
import { ItemBox, EpicInfoBox, BlankItemBox } from "./story";
import { generateRandomKey, padData } from "../../utils";
import { useDrop } from "react-dnd";
import HasuraService from "../../../hasura/hasura-service";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCurrentUser } from "../../../auth/auth-methods";

const EpicColumn = ({ columnData, type, epicId, setTaskModalVisable, setTasks, setStories }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ["todos", "doings", "dones"],
    hover: (item) => {},
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: async (droppedItem) => {
      if (droppedItem.epicId === epicId && droppedItem.type !== type) {
        let storyToUpdate = droppedItem.itemData;
        storyToUpdate.column = type;
        if (type === "dones") {
          storyToUpdate.collapsed = true;
        } else {
          storyToUpdate.collapsed = false;
        }
        setStories((prevData) =>
          prevData.map((c) => (c.id === storyToUpdate.id ? storyToUpdate : c))
        );

        const hs = new HasuraService();
        try {
          hs.updateStoryColumn(storyToUpdate.id, type);
        } catch (error) {
          console.error(error);
        }
      }
    },
  });

  return (
    <div ref={drop}>
      <Box>
        {columnData.map((item) =>
          item === "" ? (
            <BlankItemBox itemData={item} type={type} epicId={epicId} key={generateRandomKey()} />
          ) : (
            <ItemBox
              itemData={item}
              type={type}
              epicId={epicId}
              key={generateRandomKey()}
              setTaskModalVisable={setTaskModalVisable}
              setTasks={setTasks}
              setStories={setStories}
            />
          )
        )}
      </Box>
    </div>
  );
};

const Epic = ({ epic, epics, setEpics, stories, setStories, setRearrangeVisable, user }) => {
  const [confirmEpicDelete, setConfirmEpicDelete] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(epic.collapsed);

  epic = padData(epic);

  const handleDelete_Epic = async () => {
    setEpics((prevData) => prevData.filter((c) => c.id !== epic.id));
    setStories((prevData) => prevData.filter((c) => c.epicid !== epic.id));
    const hs = new HasuraService();
    try {
      await hs.deleteEpic(epic.id);
    } catch (error) {
      console.error(error);
    }
  };

  const collapseEpic = async () => {
    const user = await getCurrentUser();
    setIsCollapsed(!isCollapsed);
    setEpics((prevData) =>
      prevData.map((c) => (c.id === epic.id ? { ...c, collapsed: !isCollapsed } : c))
    );

    const hs = new HasuraService();
    try {
      hs.updateEpicCollapsed(epic.id, !isCollapsed);
    } catch (error) {
      console.error(error);
    }
  };

  const boxColor = "gold";

  return !isCollapsed ? (
    <Box display="flex" marginBottom={2}>
      <Box marginTop={1}>
        <IconButton aria-label="collapse" onClick={collapseEpic}>
          <ExpandLessIcon />
        </IconButton>
        {confirmEpicDelete ? (
          <Box display="flex" flexDirection="column">
            <IconButton aria-label="close" onClick={() => setConfirmEpicDelete(false)}>
              <CloseIcon />
            </IconButton>
            <IconButton aria-label="delete-confirm" onClick={handleDelete_Epic}>
              <CheckIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <IconButton aria-label="delete" onClick={() => setConfirmEpicDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <IconButton aria-label="delete" onClick={() => setRearrangeVisable(true)}>
          <DragIndicatorIcon />
        </IconButton>
      </Box>
      <Box display="flex" border="1px solid lightgrey" borderRadius={1} padding={1}>
        <EpicInfoBox
          epicData={epic.tasks[0]}
          epicId={epic.id}
          setStories={setStories}
          stories={stories}
          epics={epics}
          setEpics={setEpics}
          user={user}
        />
        <Divider orientation="vertical" flexItem sx={{ marginY: 2, marginX: 2 }} />
        <EpicColumn
          columnData={epic.todos}
          type={"todos"}
          epicId={epic.id}
          setTasks={setStories}
          setStories={setStories}
        />
        <Divider orientation="vertical" flexItem sx={{ marginY: 2, marginX: 2 }} />
        <EpicColumn
          columnData={epic.doings}
          type={"doings"}
          epicId={epic.id}
          setTasks={setEpics}
          setStories={setStories}
        />
        <Divider orientation="vertical" flexItem sx={{ marginY: 2, marginX: 2 }} />
        <EpicColumn
          columnData={epic.dones}
          type={"dones"}
          epicId={epic.id}
          setTasks={setEpics}
          setStories={setStories}
        />
      </Box>
    </Box>
  ) : (
    <Box display="flex" marginBottom={2}>
      <IconButton aria-label="expand" onClick={collapseEpic}>
        <ExpandMoreIcon />
      </IconButton>
      <Box
        display="flex"
        width={1000}
        border={`2px solid ${boxColor}`}
        borderRadius={1}
        padding={1}
        alignItems="center"
      >
        <Typography color="grey" fontSize="15px" marginRight={1}>
          {epic.id}
        </Typography>
        <Typography>{epic.tasks[0].text}</Typography>
      </Box>
    </Box>
  );
};

export const EpicBoard = ({ epics, setEpics, stories, setStories, setRearrangeVisable, user }) => {
  return (
    <Box width={1038}>
      <Box display="flex" flex={1} marginLeft={5} marginBottom={1}>
        <Box flex={1}></Box>
        <Box flex={1} textAlign="center">
          <Typography variant="h6">Todos</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="h6">Doing</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="h6">Done</Typography>
        </Box>
      </Box>
      {epics
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((epic) => {
          return (
            <Box key={epic.id}>
              <Epic
                epic={epic}
                epics={epics}
                setEpics={setEpics}
                stories={stories}
                setStories={setStories}
                setRearrangeVisable={setRearrangeVisable}
                user={user}
              />
            </Box>
          );
        })}
    </Box>
  );
};
