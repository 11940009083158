import { useState } from "react";
import { Box, Button, Modal, Typography, InputBase, Divider, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HasuraService from "../../../hasura/hasura-service";
import CloseIcon from "@mui/icons-material/Close";

export const StoryModal = ({ visable, setTaskModalVisable, setStories, story, epic }) => {
  const [modalState, setModalState] = useState({
    id: story.id,
    text: story.tasks[0].text,
    description:
      story.tasks[0].description === "" ? "No description yet" : story.tasks[0].description,
  });
  const [showTextField, setShowTextField] = useState(false);
  const [input, setInput] = useState(story.tasks[0].description);

  const updateDescription = async () => {
    const fixedInput = input.replace(/"/g, "'");

    // update modal state
    setModalState((prevState) => ({
      ...prevState,
      description: input === "" ? "No description yet" : fixedInput,
    }));

    // update app state
    setStories((prevState) =>
      prevState.map((c) => (c.id === story.id ? { ...c, description: fixedInput } : c))
    );

    // update Hasura
    const hs = new HasuraService();
    try {
      await hs.updateStoryDescription(fixedInput, story.id);
    } catch (error) {
      console.error(error);
    }

    setShowTextField(false);
    setInput("");
  };

  const openTextField = () => {
    if (modalState.description === "No description yet") {
      setInput("");
    } else {
      setInput(modalState.description);
    }
    setShowTextField(true);
  };

  const closeTextField = () => {
    setShowTextField(false);
  };

  const changeType = () => {
    setStories((prevData) =>
      prevData.map((c) =>
        c.id === story.id ? { ...c, type: story.type === "bug" ? "feature" : "bug" } : c
      )
    );
    const hs = new HasuraService();
    try {
      hs.updateStoryType(story.id, story.type === "bug" ? "feature" : "bug");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={visable}
      onClose={() => {
        setTaskModalVisable(false);
        setInput(modalState.description);
        setShowTextField(false);
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 800,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom>{modalState.id}</Typography>
          {epic.id && (
            <Box
              border="1px solid gold"
              borderRadius={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={1}
            >
              <Typography>Epic {epic.id}</Typography>
            </Box>
          )}
          <Button onClick={changeType}>{story.type === "feature" ? "Feature" : "Bug"}</Button>
        </Box>
        <Typography variant="h6" marginBottom={4}>
          {modalState.text}
        </Typography>
        {showTextField ? (
          <Box>
            <Box
              border="1px solid lightgrey"
              borderRadius={1}
              display="flex"
              alignItems="center"
              marginBottom={2}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Description"
                inputProps={{ "aria-label": "Description" }}
                variant="standard"
                onChange={(e) => setInput(e.target.value)}
                value={input}
                multiline
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="add"
                onClick={updateDescription}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
            <IconButton type="button" aria-label="close" onClick={closeTextField}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <Typography paddingLeft={1} gutterBottom>
              Description:
            </Typography>
            <Box border="1px solid lightgrey" borderRadius={2} padding={2} marginBottom={2}>
              <Typography gutterBottom>{modalState.description}</Typography>
            </Box>
            <Button onClick={openTextField}>Edit Description</Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
