import { useState } from "react";
import { Box } from "@mui/material";
import { LoginSignUpBox } from "./components/login_signupBox";
import { useParams } from "react-router-dom";
import * as Auth from "aws-amplify/auth";
import { sparseError } from "../auth-methods";

async function signIn({ email: username, password }, callbackURL = null) {
  let result;
  try {
    const user = await Auth.signIn({ username, password }).then(({ isSignedIn }) => {
      if (isSignedIn) {
        window.location.href = "/";
      }
    });
  } catch (error) {
    console.error(error);
    return callbackURL ? sparseError(error) : error;
  }
  return result;
}

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [badEorP, setBadEorP] = useState(false);
  const { returnpage } = useParams();

  const handleSignIn = () => {
    signIn({ email: email, password: password }, `/${returnpage}`).then((x) => {
      if (x === 1) {
        setBadEorP(true);
      }
    });
  };

  return (
    <Box
      backgroundColor="background.primary"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <LoginSignUpBox
        textFields={[
          ["Email", "email", (e) => setEmail(e.target.value)],
          ["Password", "password", (e) => setPassword(e.target.value)],
        ]}
        button={["Sign In", handleSignIn]}
        bottomText={["Sign up!", "/signup"]}
        errorStatus={badEorP}
        errorMessage="Incorrect email or password!"
      />
    </Box>
  );
};

export { SignIn };
