import { useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { LoginSignUpBox } from "./components/login_signupBox";

import * as Auth from "aws-amplify/auth";
import { sparseError } from "../auth-methods";
import HasuraService from "../../hasura/hasura-service";

async function confirm(email, name, username, code) {
  try {
    await Auth.confirmSignUp({
      username: username,
      confirmationCode: code,
    }).then(({ isSignUpComplete, nextStep }) => {
      if (isSignUpComplete) {
        const hs = new HasuraService();
        hs.createUser(email)
          .then((x) => (window.location.href = `/signin`))
          .catch((e) => console.error(e));
      }
    });
  } catch (error) {
    console.error("error confirming sign up", error);
    return sparseError(error);
  }
}

const Confirm = () => {
  const [code, setCode] = useState("");
  const [incorrectCode, setIncorrectCode] = useState(false);

  let { name, email, username } = useParams();

  const handleConfirm = () => {
    confirm(email, name, username, code).then((x) => {
      if (x === 3) {
        setIncorrectCode(true);
      }
    });
  };

  return (
    <Box
      backgroundColor="background.primary"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <LoginSignUpBox
        textFields={[["Code", "code", (e) => setCode(e.target.value)]]}
        button={["Confirm", handleConfirm]}
        text="Check your email for your code!"
        errorMessage="Incorrect Code"
        errorStatus={incorrectCode}
        username={username}
      />
    </Box>
  );
};

export { Confirm };
