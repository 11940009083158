import * as Auth from "aws-amplify/auth";

export async function deleteUser() {
  try {
    const result = await Auth.deleteUser();
    return result;
  } catch (error) {
    console.error("Error deleting user", error);
  }
}
