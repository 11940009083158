import React, { useState } from "react";
import { Box, Typography, IconButton, InputBase, Divider } from "@mui/material";
import { useDrag } from "react-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HasuraService from "../../../hasura/hasura-service";
import { TaskModal } from "./task-Box-Modal";
import { getCurrentUser } from "../../../auth/auth-methods";
import { StoryModal } from "./story-Box-Modal";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BlankItemBox = ({ itemData, type, storyId }) => {
  const [, drag] = useDrag({
    type: type,
    item: { type: type, itemData, storyId },
  });
  return (
    <div ref={drag}>
      <Box
        height={150}
        width={175}
        border={`1px solid #FFFFFF`}
        borderLeft={`5px solid #FFFFFF`}
        borderRadius={1}
        padding={1}
        marginBottom={1.5}
        margin={1.5}
      ></Box>
    </div>
  );
};

export const ItemBox = ({ itemData, type, storyId, setTasks }) => {
  const [confirmTaskDelete, setConfirmTaskDelete] = useState(false);
  const [taskModalVisable, setTaskModalVisable] = useState(false);
  const [, drag] = useDrag({
    type: type,
    item: { type: type, itemData, storyId },
  });

  const handleDelete_Task = async () => {
    const user = await getCurrentUser();
    const hs = new HasuraService();
    if (user) {
      try {
        hs.deleteTask(itemData.id);
      } catch (error) {
        console.error(error);
      }
    }
    setTasks((prevData) => prevData.filter((c) => c.id !== itemData.id));
  };

  return (
    <div ref={drag}>
      <TaskModal
        visable={taskModalVisable}
        setTaskModalVisable={setTaskModalVisable}
        itemData={{ type: "task", data: itemData, storyId: storyId }}
        setTasks={setTasks}
      />
      <Box
        height={150}
        width={175}
        border={`1px solid #FAFA00`}
        borderLeft={`5px solid #FAFA00`}
        borderRadius={1}
        textAlign="left"
        padding={1}
        marginBottom={1.5}
        margin={1.5}
        display="flex"
        flexDirection="column"
        position="relative"
        overflow="hidden"
        boxShadow={1}
      >
        <Box flex={4} overflow="hidden" onClick={() => setTaskModalVisable(true)}>
          <Typography gutterBottom color="grey" fontSize="15px">
            {itemData.id}
          </Typography>
          <Typography>{itemData.text}</Typography>
        </Box>
        {confirmTaskDelete ? (
          <Box display="flex">
            <Box flex={1} onClick={() => setTaskModalVisable(true)} />
            <IconButton aria-label="delete-confirm" onClick={handleDelete_Task}>
              <CheckIcon />
            </IconButton>
            <IconButton aria-label="close" onClick={() => setConfirmTaskDelete(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box display="flex">
            <Box flex={1} onClick={() => setTaskModalVisable(true)} />
            <IconButton aria-label="delete" onClick={() => setConfirmTaskDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </div>
  );
};

export const StoryInfoBox = ({ setTasks, user, setStories, story, epic }) => {
  const [input, setInput] = useState("");
  const [taskModalVisable, setTaskModalVisable] = useState(false);

  const handleClick_newTodo = async () => {
    const hs = new HasuraService();
    const fixedInput = input.replace(/"/g, "'");
    let taskid = user
      ? await hs.createTask(fixedInput, story.id, user.id)
      : Math.floor(Math.random() * 10000);

    const newTask = {
      column: "todos",
      description: "",
      id: taskid,
      storyid: story.id,
      text: input,
      userid: taskid,
    };

    setTasks((prevData) => [...prevData, newTask]);
    setInput("");
  };

  const typeColor = story.type === "bug" ? "red" : "lightgreen";
  return (
    <Box
      height={150}
      width={175}
      border={`1px solid ${typeColor}`}
      borderLeft={`5px solid ${typeColor}`}
      borderRadius={1}
      textAlign="left"
      padding={1}
      marginBottom={1.5}
      margin={1.5}
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden"
      boxShadow={1}
    >
      <StoryModal
        visable={taskModalVisable}
        setTaskModalVisable={setTaskModalVisable}
        setStories={setStories}
        story={story}
        epic={epic}
      />
      <Box
        flex={4}
        overflow="hidden"
        onClick={() => setTaskModalVisable(true)}
        sx={{ cursor: "pointer" }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography gutterBottom color="grey" fontSize="15px">
            {story.id}
          </Typography>
          {story.epicid && <FontAwesomeIcon icon={faCrown} color="gold" />}
        </Box>

        <Typography>{story.tasks[0].text}</Typography>
      </Box>
      <Box border="1px solid lightgrey" borderRadius={1} display="flex" alignItems="center">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Add Task"
          inputProps={{ "aria-label": "add task" }}
          variant="standard"
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="add" onClick={handleClick_newTodo}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
