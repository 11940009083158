import { Box, Typography } from "@mui/material";
import packageJson from "../../../package.json";

export const Footer = () => {
  const version = packageJson.version;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="end"
      marginTop={3}
    >
      <Typography variant="body2">SuperSimpleScrum v{version}</Typography>
      <Typography variant="body2">Brandon McHugh 2023</Typography>
      <Typography variant="body2" gutterBottom>
        <a href="http://www.brandon-mchugh.com" target="_blank">
          brandon-mchugh.com
        </a>
      </Typography>
    </Box>
  );
};
