import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  ChangePassword,
  Confirm,
  DeleteUserConfirmation,
  SignIn,
  SignUp,
} from "./auth/screens";
import ErrorElement from "./errorElement";
import SuperSimpleScrumMain from "./ui/main";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SuperSimpleScrumMain />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/signin",
    element: <SignIn />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/signup",
    element: <SignUp />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/signup/confirm/:name/:email/:username",
    element: <Confirm />,
    errorElement: <ErrorElement />,
    loader: ({ params }) => {
      return params;
    },
  },
  {
    path: "/changepassword",
    element: <ChangePassword />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/deleteuserconfirmation/:userid",
    element: <DeleteUserConfirmation />,
    errorElement: <ErrorElement />,
    loader: ({ params }) => {
      return params;
    },
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
