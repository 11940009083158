import * as Auth from "aws-amplify/auth";

const getCurrentUser = () => {
  return Auth.fetchUserAttributes({
    bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then((user) => user)
    .catch((err) => console.error(err));
};

export { getCurrentUser };
