import { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, IconButton } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";

import HasuraService from "../../../hasura/hasura-service";
import { generateRandomKey } from "../../utils";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const Epic = ({ epic, setBoxes }) => {
  const [, drag] = useDrag({
    type: "EPIC",
    item: { ...epic },
  });

  const [{ isOver }, drop] = useDrop({
    accept: ["EPIC"],
    hover: (item) => {},
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: async (droppedItem) => {
      if (droppedItem.id !== epic.id) {
        setBoxes((prevState) => {
          return prevState.reduce((a, c) => {
            if (c.id === droppedItem.id) {
              return a;
            } else if (c.id === epic.id) {
              return [...a, droppedItem, c];
            } else {
              return [...a, c];
            }
          }, []);
        });
      }
    },
  });

  return (
    <div ref={drop} style={{ flex: 20, overflow: "hidden" }}>
      <div ref={drag} style={{ cursor: "grab" }}>
        <Box
          sx={{
            border: "1px solid gold",
            margin: "4px",
            overflow: "clip",
          }}
          display="flex"
          alignItems="baseline"
          padding={1}
        >
          <Typography marginRight={1} color="grey">
            {epic.id}:
          </Typography>
          <Typography>{epic.title}</Typography>
        </Box>
      </div>
    </div>
  );
};

const RearrangeEpicsModal = ({ rearrangeVisable, setRearrangeVisable, epics, setEpics }) => {
  const [boxes, setBoxes] = useState(epics);

  useEffect(() => {
    setBoxes(epics);
  }, [epics]);

  async function confirmRearrange() {
    const updateEpicOrderNumbers = boxes.map((c, i) => ({ ...c, orderNumber: i }));
    const hs = new HasuraService();
    try {
      await hs.updateEpicOrder(updateEpicOrderNumbers);
    } catch (error) {
      console.error(error);
    }
    setEpics(updateEpicOrderNumbers);
    setRearrangeVisable(false);
  }

  const moveToTop = (epic) => {
    setBoxes((prevState) => {
      let newState = prevState.filter((c) => c.id !== epic.id);
      return [epic, ...newState];
    });
  };
  const moveToBottom = (epic) => {
    setBoxes((prevState) => {
      let newState = prevState.filter((c) => c.id !== epic.id);
      return [...newState, epic];
    });
  };

  return (
    <Modal open={rearrangeVisable} onClose={() => setRearrangeVisable(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 400,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Box>
          {boxes.map((epic) => (
            <Box key={generateRandomKey()} display="flex" alignItems="center" flex={1}>
              <Box display="flex" flexDirection="column" marginRight={1} marginLeft={-2} flex={1}>
                <KeyboardDoubleArrowUpIcon
                  sx={{ fontSize: 20, cursor: "pointer" }}
                  onClick={() => moveToTop(epic)}
                />
                <KeyboardDoubleArrowDownIcon
                  sx={{ fontSize: 20, cursor: "pointer" }}
                  onClick={() => moveToBottom(epic)}
                />
              </Box>
              <Epic epic={epic} setBoxes={setBoxes} />
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button onClick={() => confirmRearrange()}>CONFIRM</Button>
        </Box>
      </Box>
    </Modal>
  );
};
export { RearrangeEpicsModal };
