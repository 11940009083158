export const firstTasks = [
  {
    column: "todos",
    description: "",
    id: 0,
    text: "Sign up!",
    userid: 0,
    storyid: 0,
  },
  {
    column: "todos",
    description: "",
    id: 1,
    text: "Sign in!",
    userid: 0,
    storyid: 0,
  },
];

export const firstStories = [
  {
    collapsed: false,
    description: "",
    id: 0,
    orderNumber: 0,
    title: "Log in to your account!",
    type: "feature",
  },
];

export const categories = ["tasks", "todos", "doings", "dones"];

// This function adds blank strings to the arrays,
// making them all equal length,
// so each category in each story is the same height
export function padData(storyData) {
  // first, remove all whitespace
  categories.forEach((category) => {
    storyData[category] = storyData[category].reduce(
      (acc, curr) => (curr === "" ? acc : [...acc, curr]),
      []
    );
  });
  // find the longest column
  const maxLength = Math.max(...categories.map((category) => storyData[category].length));
  // pad all columns
  categories.forEach((category) => {
    const lengthDiff = maxLength - storyData[category].length;
    storyData[category] = [...storyData[category], ...Array(lengthDiff).fill("")];
  });
  return storyData;
}

export function capitalize(str) {
  if (Array.isArray(str)) {
    return str.map((c) => capitalize(c));
  } else {
    return str
      .split(" ")
      .reduce((acc, curr) => {
        let tempStr = curr.split("");
        tempStr[0] = tempStr[0].toUpperCase();
        return [...acc, tempStr.join("")];
      }, [])
      .join(" ");
  }
}

export function generateRandomKey() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    key += characters.charAt(randomIndex);
  }
  return key;
}
