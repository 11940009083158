import { useState, Fragment } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { signOut } from "../../auth/auth-methods";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";

const UserMenu = ({ user, setData, setUser, firstTasks }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    signOut().then(() => {
      setUser(null);
      setData(firstTasks);
    });
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 48, height: 48 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user ? (
          <MenuItem
            onClick={() => {
              handleSignOut();
              handleClose();
            }}
          >
            <Logout /> Sign Out
          </MenuItem>
        ) : (
          <Box>
            <MenuItem onClick={() => (window.location.href = "/signin")}>
              <Avatar /> Sign In
            </MenuItem>
            <MenuItem onClick={() => (window.location.href = "/signup")}>
              <Avatar /> Sign Up
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Fragment>
  );
};

const Header = ({ user, setUser, setData, firstTasks }) => {
  return (
    <Box
      display="flex"
      // flex={1}
      alignItems="center"
      padding={4}
      backgroundColor="#2196F3"
      color="white"
      height={75}
    >
      <Box flex={1}>
        <Typography variant="h4" color="white" gutterBottom>
          Super Simple Scrum
        </Typography>
        <Typography variant="subtitle1" color="white">
          Making scrum super simple.
        </Typography>
      </Box>
      <Box>
        <UserMenu user={user} setData={setData} setUser={setUser} firstTasks={firstTasks} />
      </Box>
    </Box>
  );
};

export { Header };
