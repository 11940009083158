export default class HasuraService {
  constructor() {}

  async fetchGraphQL(operationsDoc, operationName, variables = {}) {
    const result = await fetch(`${process.env.REACT_APP_HASURA_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName,
      }),
      headers: {
        "content-type": "application/json",
        "x-hasura-admin-secret": `${process.env.REACT_APP_HASURA_ADMIN_SECRET}`,
      },
    });

    return await result.json();
  }

  async getStuff(email) {
    const operation = `
      query getStuff {
        scrum_v2_Users(where: {email: {_eq: "${email}"}}) {
          Epics {
            collapsed
            description
            id
            orderNumber
            title
            userid
          }
          Stories {
            collapsed
            column
            description
            epicid
            id
            orderNumber
            title
            type
            userid
          }
          Tasks {
            column
            description
            id
            storyid
            text
            userid
          }
          id
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "getStuff");
    return data ? data.scrum_v2_Users[0] : errors;
  }

  async createTask(text, storyid, userid) {
    const operation = `
      mutation createTask {
        insert_scrum_v2_Tasks(objects: {column: "todos", description: "", storyid: ${storyid}, text: "${text}", userid: ${userid}}) {
          returning {
            id
          }
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "createTask");
    return data ? data.insert_scrum_v2_Tasks.returning[0].id : errors;
  }

  async createEpic(userid, title, orderNumber) {
    const operation = `
      mutation createEpic {
        insert_scrum_v2_Epics(objects: {userid: ${userid}, title: "${title}", orderNumber: ${orderNumber}, description: "", collapsed: false}) {
          returning {
            id
          }
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "createEpic");
    return data ? data.insert_scrum_v2_Epics.returning[0].id : errors;
  }

  async createStory(orderNumber, title, type, userid, epicid) {
    const operation = `
      mutation createStory {
        insert_scrum_v2_Stories(objects: {collapsed: false, description: "", orderNumber: ${orderNumber}, epicid: ${epicid}, title: "${title}", type: "${type}", userid: ${userid}}) {
          returning {
            id
          }
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "createStory");
    return data ? data.insert_scrum_v2_Stories.returning[0].id : errors;
  }

  async deleteEpic(epicid) {
    const operation = `
      mutation deleteEpic {
        delete_scrum_v2_Epics(where: {id: {_eq: ${epicid}}}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "deleteEpic");
    return data ? data.delete_scrum_stories : errors;
  }

  async deleteStory(storyid) {
    const operation = `
      mutation deleteStory {
        delete_scrum_v2_Stories(where: {id: {_eq: ${storyid}}}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "deleteStory");
    return data ? data.delete_scrum_stories : errors;
  }

  async deleteStories(epicid) {
    const operation = `
      mutation deleteStories {
        delete_scrum_v2_Stories(where: {Epic: {id: {_eq: 6}}}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "deleteStories");
    return data ? data.delete_scrum_stories : errors;
  }

  async deleteTask(taskid) {
    const operation = `
      mutation deleteTask {
        delete_scrum_v2_Tasks(where: {id: {_eq: ${taskid}}}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "deleteTask");
    return data ? data : errors;
  }

  async deleteTasks(taskids) {
    console.log(taskids);
    const operation = `
      mutation deleteTasks {
        delete_scrum_v2_Tasks(where: {id: {_in: [${taskids}]}}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "deleteTasks");
    return data ? data : errors;
  }

  async updateEpicCollapsed(id, collapsed) {
    const operation = `
      mutation updateEpicCollapsed {
        update_scrum_v2_Epics(where: {id: {_eq: ${id}}}, _set: {collapsed: ${collapsed}}) {
          affected_rows
        }
      }
      `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateEpicCollapsed");
    return data ? data : errors;
  }

  async updateStoryCollapsed(id, collapsed) {
    const operation = `
      mutation updateStoryCollapsed {
        update_scrum_v2_Stories(where: {id: {_eq: ${id}}}, _set: {collapsed: ${collapsed}}) {
          affected_rows
        }
      }
      `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateStoryCollapsed");
    return data ? data : errors;
  }

  async updateTaskDescription(text, taskid) {
    const operation = `
      mutation updateTaskDescription {
        update_scrum_v2_Tasks(where: {id: {_eq: ${taskid}}}, _set: {description: "${text}"}) {
          affected_rows
        }
      }
      `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateTaskDescription");
    return data ? data : errors;
  }

  async updateStoryDescription(text, storyid) {
    const operation = `
      mutation updateStoryDescription {
        update_scrum_v2_Stories(where: {id: {_eq: ${storyid}}}, _set: {description: "${text}"}) {
          affected_rows
        }
      }    
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateStoryDescription");
    return data ? data : errors;
  }

  async updateEpicDescription(text, epicid) {
    const operation = `
      mutation updateEpicDescription {
        update_scrum_v2_Epics(where: {id: {_eq: ${epicid}}}, _set: {description: "${text}"}) {
          affected_rows
        }
      }    
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateEpicDescription");
    return data ? data : errors;
  }

  async updateTaskColumn(id, newColumn) {
    const operation = `
      mutation updateTaskColumn {
        update_scrum_v2_Tasks(where: {id: {_eq: ${id}}}, _set: {column: "${newColumn}"}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateTaskColumn");
    return data ? data : errors;
  }

  async updateStoryColumn(id, newColumn) {
    const operation = `
      mutation updateStoryColumn {
        update_scrum_v2_Stories(where: {id: {_eq: ${id}}}, _set: {column: "${newColumn}", collapsed: ${
      newColumn === "dones" ? "true" : "false"
    }}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateStoryColumn");
    return data ? data : errors;
  }

  async updateStoryOrder(stories) {
    stories.forEach(async (story) => {
      const operation = `
        mutation updateStoryOrderNumber {
          update_scrum_v2_Stories(where: {id: {_eq: ${story.id}}}, _set: {orderNumber: ${story.orderNumber}}) {
            affected_rows
          }
        }
      `;
      try {
        const { errors, data } = await this.fetchGraphQL(operation, "updateStoryOrderNumber");
      } catch (error) {
        console.error(error);
      }
    });
  }

  async updateStoryType(storyid, type) {
    const operation = `
      mutation updateStoryType {
        update_scrum_v2_Stories(where: {id: {_eq: ${storyid}}}, _set: {type: "${type}"}) {
          affected_rows
        }
      }
    `;
    const { errors, data } = await this.fetchGraphQL(operation, "updateStoryType");
    return data ? data : errors;
  }

  async updateEpicOrder(epics) {
    epics.forEach(async (epic) => {
      const operation = `
        mutation updateEpicOrderNumber {
          update_scrum_v2_Epics(where: {id: {_eq: ${epic.id}}}, _set: {orderNumber: ${epic.orderNumber}}) {
            affected_rows
          }
        }
      `;
      try {
        const { errors, data } = await this.fetchGraphQL(operation, "updateEpicOrderNumber");
      } catch (error) {
        console.error(error);
      }
    });
  }
}
